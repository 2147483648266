<template lang='pug'>
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`crewing-verification-${Object.keys(statement?.behavior)[0]}`, { id: statement?.id })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock" :statement="statement")
    EditStatus(v-if="statement?.behavior?.viewEditStatusBlock" :statement="statement")
    ViewPhotoList(
      v-else-if="statement?.behavior?.viewFilesBlock"
      isHiddenActionsButton
      :sailorDocument="statement"
      documentType="statement")
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
export default {
  name: 'StatementCrewingVerification',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue'),
    EditStatus: () => import('./EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList')
  },

  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.statement, 'viewEditStatusBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          checkAccess: () => checkAccess('crewingVerification', 'editStatus', this.statement),
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess(this.type, 'files', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        {
          id: 4,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      statement: state => state.statement.crewStatementById,
      isLoading: state => state.statement.isLoading
    })
  },
  mounted () {
    this.getCrewingVerificationStatements({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getCrewingVerificationStatements'])
  }
}
</script>
